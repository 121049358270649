


import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";
import Axios from "axios";

import { BrokerVendors } from "../../../models/broker-vendor.model";
import { HollowDotsSpinner, LoopingRhombusesSpinner } from "epic-spinners";
import { MLO } from "../../../models/mlo.model";
import { AdminStaff } from "../../../models/admin-staff.model";
import { loanAndProperty } from "@/models/loan-and-property.model";

@Component({ components: { HollowDotsSpinner } })
export default class brokerDetailsComponent extends Vue {
  // public noVendors = true;
  public VENDORS: any = null;
  public COMPANYNAME = "";
  public inProcess = false;
  // public currentVT = "";
  // public vendors = new BrokerVendors();
  public vendorTypes = [];
  public isVendorEdit = false;
  // public editingAusType = "";
  // public cd = true;
  public dotLoader = false;
  public lendersShowingPassword = [];
  public vendorsShowingPassword = [];

  // public ausVendor = {
  //   freddieMac: {},
  //   fannieMae: {}
  // };
  // public loanSifter: any = {};
  // public currentVTindex = "";
  public defaultReset = "RESET";
  public defaultLenderName = "Add Lender";
  public defaultSubmitName = "Add";
  public editLenderData: any = {};
  public lenders = [];
  public lenderInfo: any = {};
  public lenderId = "";
  public delete = "yes";
  public userRole = "MLO";
  public currentUserData: any = {};
  public mlo = new MLO();
  // public adminStaff = new AdminStaff();
  public users: any = [];
  public modalUser: any = {};
  public userToResendLink = "";
  public initialEmail = null;
  public duplicateEmail = null;
  public duplicatePhoneNo = null;
  public emailExistence = false;
  public addMLO = false;
  public addAdmin = false;
  public currentUserName = null;
  public currentType = "";

  public brokerDetail: any = null;
  public mloData: any = [];
  public lendersData: any = [];
  public adminStaffData: any = [];
  public vendorsData: any = [];
  public newVendorsData: any = {};
  public ausVendorType = null;
  public addVendorType: any = null;
  public vendorEditIndex: any = null;
  public isFilterUser = false;
  // public vendorData: any = [];
  public creditVendorList: any = [];
  public mloFullName = null;
  public brokerIds = null;

  public isValidField(credential) {
    return ["lendersShowingPassword", "vendorsShowingPassword"].includes(
      credential
    );
  }

  public togglePasswordDisplay(credential, role) {
    if (this.isValidField(credential)) {
      this[credential] = this[credential].includes(role._id)
        ? this[credential].filter(id => id != role._id)
        : [role._id, ...this[credential]];
    }
  }

  public viewModal(user, type, modalName) {
    this.currentType = user.userType;
    this.currentUserData = {};
    this.currentUserName = null;
    this.currentUserData = Object.assign({}, user);
    if (user.userType == "MLO") {
      this.addMLO = true;
      this.currentUserName = "MLO";
    } else if (user.userType == "Admin Staff") {
      this.currentUserName = "Admin Staff";
      this.addMLO = false;
    }
    this.$modal.show(modalName);
  }

  public hideModal(modalName) {
    this.duplicateEmail = null;
    this.duplicatePhoneNo = null;
    this.currentUserData = {};
    this.currentUserName = null;
    this.addMLO = false;
    this.duplicateEmail = null;
    this.$modal.hide(modalName);
  }

  public async validateAndSubmit() {
    try {
      let formValid = await this.$validator.validateAll();
      if (
        formValid &&
        this.currentUserData.email != this.duplicateEmail &&
        this.currentUserData.phone != this.duplicatePhoneNo
      ) {
        this.currentUserData.email = this.currentUserData.email.toLowerCase();
        this.currentUserData.brokerId = this.$route.query.brokerId; /*sending broker userId in mlo db brokerid field */
        this.currentUserData["checkDuplicateEmail"] = true;
        let url = this.addMLO ? "broker/addMLO" : "broker/addAdminStaff";
        let response = await Axios.post(
          BASE_API_URL + url,
          this.currentUserData);
        if (response.data.status == "success") {
          this.getBrokerDetails();
          this.$snotify.success(this.currentUserName + " added successfully");
          this.hideModal("addusermodal");
          this.duplicateEmail = null;
          this.duplicatePhoneNo = null;
        } else if (response.data.status == "duplicatePhoneAndEmail") {
          this.duplicateEmail = this.currentUserData.email;
          this.duplicatePhoneNo = this.currentUserData.phone;
        } else if (response.data.status == "duplicateEmail") {
          this.duplicateEmail = this.currentUserData.email;
        } else {
          this.duplicatePhoneNo = this.currentUserData.phone;
        }
      }
    } catch (error) {
      this.$snotify.error(error.response.message);
    }
  }
  //Function For Updating The User Info
  public async updateUser() {
    try {
      this.currentUserData.userType = this.currentType;
      this.currentUserData._id = this.currentUserData.userId;
      let formValid = await this.$validator.validateAll();
      if (!formValid) return;
      let response = await Axios.post(
        BASE_API_URL + "broker/updateUser",
        this.currentUserData);
      this.$snotify.success(
        this.currentUserData.userType + " updated successfully"
      );
      this.currentUserData = {};
      this.currentUserName = null;
      this.hideModal("editUser");
      this.getBrokerDetails();
    } catch (error) {
      this.$snotify.error("Error occurred during updating user");
    }
  }

  public async addBrokerLendor() {
    this.inProcess = true;
    let formValid = await this.$validator.validateAll();
    if (!formValid) {
      this.inProcess = false;
      return;
    }
    if (this.defaultSubmitName == "Add") {
      this.lenderInfo.addedBy = this.$store.state.sessionObject.userId;
      this.lenderInfo.addedByUserType = this.userType;
      this.lenderInfo.brokerId = this.$route.query.brokerId;
      let body = {
        brokerId: this.$route.query.brokerId,
        userId: this.$store.state.sessionObject.userId,
        userType: this.userType,
        lenders: [this.lenderInfo]
      };
      let response = await Axios.post(
        BASE_API_URL + "broker/saveBrokerLenders",
        body);

      if (response.data.hasOwnProperty("duplicateEmails")) {
        this.$snotify.error(response.data.message);
      } else {
        this.lenderInfo = {};
        this.$snotify.success(response.data.message);
        this.$modal.hide("addLender");
        this.getBrokerDetails();
      }
    } else {
      let body = {
        lenders: this.lenderInfo
      };
      let response = await Axios.post(
        BASE_API_URL + "broker/updateBrokerLenders",
        body);
      this.$snotify.success("Lender updated successfully");
      this.lenderInfo = {};
      this.defaultLenderName = "Add Lender";
      this.defaultSubmitName = "Add";
      this.getBrokerDetails();
      this.$modal.hide("addLender");
    }
    this.inProcess = false;
  }
  public async cancel() {
    this.lenderInfo = {};
    this.defaultLenderName = "Add Lender";
    this.defaultSubmitName = "Add";
    this.$modal.hide("addLender");
  }

  public closeModal() {
    this.lenderInfo = {};
    this.defaultLenderName = "Add Lender";
    this.defaultSubmitName = "Add";
    this.$modal.hide("addLender");
  }

  public async editLender(lender) {
    this.editLenderData = Object.assign({}, lender);
    this.defaultLenderName = "Edit Lender";
    this.defaultSubmitName = "Update";
    this.lenderInfo = this.editLenderData;
    this.$modal.show("addLender");
  }

  public async getBrokerDetails() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/getBrokerAllDetails",
        {
          userId: this.$route.query.userId,
          brokerId: this.$route.query.brokerId,
          filterUser: this.isFilterUser,
        }
      );
      this.brokerDetail = response.data.brokerDetails;
      this.COMPANYNAME = this.brokerDetail.companyName;
      this.mloFullName = response.data.mloFullName;
      this.brokerIds = response.data.brokerId;
      this.mloData = [];
      response.data.mloData.forEach(element => {
        element.userType = "MLO";
        this.mloData.push(element);
      });
      this.adminStaffData = [];
      response.data.adminStaffData.forEach(element => {
        element.userType = "Admin Staff";
        this.adminStaffData.push(element);
      });
      this.vendorsData = [];
      response.data.vendorData.forEach(element => {
        this.vendorsData.push(element);
      });
    } catch (error) {
      console.log(error.response);
    }
  }

  public addNewVendorsData() {
    this.newVendorsData = {
      fannieMae: {},
      freddieMac: {}
    };
  }

  public resolveVendorTypes() {
    try {
      this.vendorTypes = ["Appraisal"];
      let vendorsType = ["LOS", "Credit Vendor"];
      vendorsType.forEach(element => {
        let vendor = this.vendorsData.filter(e => e.vendorType == element);
        if (vendor.length == 0) {
          this.vendorTypes.push(element);
        }
      });
      let ausVendors = this.vendorsData.filter(
        e => e.vendorType == "AUS Vendor"
      );
      if (ausVendors.length == 0) {
        this.vendorTypes.push("AUS Vendor");
        this.ausVendorType = null;
      } else if (ausVendors.length == 1) {
        this.ausVendorType = ausVendors[0].vendorName;
        this.vendorTypes.push("AUS Vendor");
      }
      this.vendorTypes.sort();
      this.vendorTypes.push("Other");
    } catch (error) {
      console.log(error);
    }
  }

  get userType() {
    return this.$store.state.sessionObject.type;
  }

  public editVendors(index) {
    try {
      this.isVendorEdit = true;
      this.addNewVendorsData();
      if (this.vendorsData[index].vendorType == "AUS Vendor") {
        this.ausVendorType = this.vendorsData[index].vendorName;
        if (this.vendorsData[index].vendorName == "Fannie Mae")
          this.newVendorsData.fannieMae = this.vendorsData[index];
        if (this.vendorsData[index].vendorName == "Freddie Mac")
          this.newVendorsData.freddieMac = this.vendorsData[index];
      } else
        this.newVendorsData = {
          fannieMae: {},
          freddieMac: {},
          ...this.vendorsData[index]
        };
      this.vendorsData = JSON.parse(JSON.stringify(this.vendorsData));
      this.vendorTypes = [];
      this.vendorTypes.push(this.vendorsData[index].vendorType);
      this.addVendorType = this.vendorsData[index].vendorType;
      this.vendorEditIndex = index;
      this.$modal.show("addVendorModal");
    } catch (error) {
      console.log(error);
    }
  }

  public async addAndEditVendor() {
    try {
      let formValid = await this.$validator.validateAll();
      if (!formValid) {
        return;
      }
      let data = {};
      if (this.isVendorEdit) {
        if (this.addVendorType == "AUS Vendor") {
          this.newVendorsData.vendorType = "AUS Vendor";
          let ausVendorType =
            this.ausVendorType == "Fannie Mae" ? "fannieMae" : "freddieMac";
          this.newVendorsData[ausVendorType].updatedBy = this.$userId;
          this.newVendorsData[ausVendorType].updatedOn = new Date();
        } else {
          this.newVendorsData.updatedBy = this.$userId;
          this.newVendorsData.updatedOn = new Date();
        }
        data = this.newVendorsData;
      } else {
        let details = {
          addedBy: this.$userId,
          addedByUserType: this.$userType,
          brokerId: this.$route.query.brokerId,
          createdOn: new Date(),
          vendorType: this.addVendorType
        };
        if (this.newVendorsData.fannieMae.userName) {
          this.newVendorsData.fannieMae = {
            vendorName: "Fannie Mae",
            ...details,
            ...this.newVendorsData.fannieMae
          };
        }
        if (this.newVendorsData.freddieMac.userName) {
          this.newVendorsData.freddieMac = {
            vendorName: "Freddie Mac",
            ...details,
            ...this.newVendorsData.freddieMac
          };
        }
        this.newVendorsData = { ...details, ...this.newVendorsData };
        data = this.newVendorsData;
      }
      let response = await Axios.post(
        BASE_API_URL + "wemloStaff/addBrokerVendor",
        data);
      this.vendorsData = [];
      response.data.forEach(element => {
        if (element.vendorType != "Loan Sifter") this.vendorsData.push(element);
      });
      this.$modal.hide("addVendorModal");
      this.resolveVendorTypes();
      this.$snotify.success(
        this.isVendorEdit
          ? "Vendor updated successfully"
          : "Vendor added successfully"
      );
      this.isVendorEdit = false;
      this.addVendorType = null;
      this.addNewVendorsData();
    } catch (error) {
      console.log(error);
    }
  }

  public closeVendorModel() {
    this.addVendorType = null;
    this.isVendorEdit = false;
    this.resolveVendorTypes();
    this.$modal.hide("addVendorModal");
  }

  public async fetchCreditVendor() {
    try {
      let body = {
        userId: this.$userId,
        brokerId: this.$route.query.brokerId,
        userType: this.$userType,
        filterUser: this.$route.query.userType,
        vendorType: "Credit Vendor"
      };
      let response = await Axios.post(
        BASE_API_URL + "onBoarding/fetchCreditVendor",
        body);
      let responseData = response.data;
      if (responseData.addedVendor) {
        this.vendorsData = responseData.addedVendor;
        this.creditVendorList = responseData.creditVendorList;
      } else {
        this.creditVendorList = responseData.creditVendorList;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getMLODetails(mlo) {
    this.$router.replace({
      path: "/processor/broker-detail",
      query: { userId: mlo.userId, userType: mlo.userType }
    });
    this.isFilterUser = true;
    this.fetchCreditVendor();
    this.getAppovedLenderList();
    await this.getBrokerDetails();
    this.resolveVendorTypes();
  }
  public async getAppovedLenderList() {
    try {

      const brokerId = this.$route.query.brokerId
        ? this.$route.query.brokerId
        : this.$route.query.userId;

      let body = {
        userId: this.$userId,
        brokerId,
        userType: this.$userType,
        filterUser: this.$route.query.userType,
        type: this.defaultLenderName
      };
      let response = await Axios.post(
        BASE_API_URL + "onBoarding/getAppovedLenderList",
        body);

      this.lendersData = response.data.addedData;
    } catch (error) {
      console.log(error.response);
    }
  }
  public getUserType() {
    if (this.$route.query.userType == "MLO") {
      return "MLO Information";
    } else {
      return "Broker Information";
    }
  }

  async mounted() {
    this.fetchCreditVendor();
    await this.getBrokerDetails();
    this.resolveVendorTypes();
    this.getAppovedLenderList();
  }
}
