








































import { Component, Vue, Prop, Mixins } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";

import { HollowDotsSpinner } from "epic-spinners";
import Axios from "axios";
@Component({ components: { HollowDotsSpinner } })
export default class DeleteMlo extends Vue {
  @Prop()
  public body: any;

  public async deleteMLO() {
    this.$modal.show("confirmDeleteFileModal");
    try {
      let body = {
        brokerId: this.body.brokerId
      };
      let response = await Axios.post(
        BASE_API_URL + "broker/deleteMloCollection",
        body);
      this.$snotify.success("Data deleted Sucessfully");
      this.$modal.hide("confirmDeleteFileModal");
      this.$emit("refreshMloList");
    } catch (error) {
      console.log(error.response);
    }
  }
  mounted() {
    this.$modal.show("confirmDeleteFileModal");
  }
}
