


import { Component } from "vue-property-decorator";
import { BASE_API_URL, CLIENT_URL } from "@/config";
import Axios from "axios";
import { HollowDotsSpinner } from "epic-spinners";
import { AdminStaff } from "../../../models/admin-staff.model";
import UpdateUserInfo from "../../../views/UpdateUserInfo.vue";
import DeleteMlo from "../../../views/DeleteMlo.vue";
import stores from "../../../store/store";
const store: any = stores;
import { mixins } from "vue-class-component";
import CommonMixin from "@/mixins/common.mixin";
import UpdateUserStatus from "@/views/UpdateUserStatus.vue";
import { ACCESS_RIGHTS_COMPONENTS, VENDOR_TYPES } from "../../../constants";
import _ from 'lodash'

@Component({
  components: {
    HollowDotsSpinner,
    UpdateUserInfo,
    DeleteMlo,
    UpdateUserStatus
  }
})
export default class brokerDetailsComponent extends mixins(CommonMixin) {
  public inProcess = false;
  // public vendors = new BrokerVendors();
  public vendorTypes = VENDOR_TYPES;
  public ausVendorSubTypes = ['Fannie Mae', 'Freddie Mac'];
  public dotLoader = false;
  public userSpecificLoader = null;
  // public loanSifter: any = {};
  public defaultReset = "RESET";
  public defaultLenderName = "Add Lender";
  public defaultSubmitName = "Add";
  public editLenderData: any = {};
  public lenders = [];
  public lenderInfo: any = {
    lenderNameAndId: {}
  };
  public lenderId = "";
  public delete = "yes";
  public userRole = "MLO";
  public currentUserData: any = {};
  public mlo: any = {};
  public adminStaff = new AdminStaff();
  public users: any = [];
  public modalUser: any = {};
  public userToResendLink = "";
  public initialEmail = null;
  public duplicateEmail = null;
  public duplicatePhone = null;
  public emailExistence = false;
  public addMLO = false;
  public addAdmin = false;
  public currentUserName = null;
  public currentType = "";
  public statusChangingIndex = null;
  //public verified = false;
  public updateStaffEmail = "";
  public updateStaffUserId = "";
  public userTypes = "";
  public emailVerified = "";
  public tokenGenerator: any = null;
  public mloId = null;
  public personalInfo = [];
  public showDeleteMLOComponent = false;
  public body = {};
  public brokerDetail: any = null;
  public mloData: any = [];
  public vendorData: any = [];
  public lenderData: any = [];
  public adminStaffData: any = [];
  public newVendorsData: any = {};
  public addVendorType: any = null;
  public subAusType: any = null;
  public isVendorEdit = false;
  public vendorEditIndex = null;
  public ausVendorType = "";
  public open = false;
  public list: any = null;
  public userInfo: any;
  public borrowersLoanTxnId = null;
  public delMloAdminId = null;
  public delType = null;
  public deleteOpt = false;
  public message = null;
  public userExist = false;
  public lenderList: any = [];
  public addedLenders: any = [];
  public errorMessage = null;
  public hasErrorMessage = false;
  public isPpeLender = false;
  public ppelenderName = null;
  public hasLenderName = true;
  public creditVendorList: any = [];
  public creditInfo: any = {
    vendorNameAndId: {}
  };
  public credit = null;
  public file: any = [];
  public val = false;
  public wemloLender: any = {};
  public lender = null;
  public showLOCredentials = [];
  public showWemloCredentials = [];
  public vendorsShowingPassword = [];

  public isFilterUser = false;
  public mloFullName = null;
  public brokerIds = null;
  public selectLender: boolean = false;
  public lenderDotLoader = false;
  public vendorDotLoader = false;
  // public showData: boolean = false;

  public isValidField(credential) {
    return [
      "showLOCredentials",
      "showWemloCredentials",
      "vendorsShowingPassword"
    ].includes(credential);
  }

  public togglePasswordDisplay(credential, role) {
    if (this.isValidField(credential)) {
      this[credential] = this[credential].includes(role._id)
        ? this[credential].filter(id => id != role._id)
        : [role._id, ...this[credential]];
    }
  }

  public viewModal(user, type, modalName) {
    this.currentType = user.userType;
    this.currentUserData = {};
    this.currentUserName = null;
    this.currentUserData = Object.assign({}, user);
    if (user.userType == "MLO") {
      this.addMLO = true;
      this.currentUserName = "MLO";
    } else if (user.userType == "Admin Staff") {
      this.currentUserName = "Admin Staff";
      this.addMLO = false;
    }
    this.$modal.show(modalName);
  }

  public hideModal(modalName) {
    this.duplicatePhone = null;
    this.currentUserData = {};
    this.currentUserName = null;
    this.addMLO = false;
    this.duplicateEmail = null;
    this.$modal.hide(modalName);
  }

  public async getBrokerDetails() {
    try {
      this.dotLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/getBrokerAllDetails",
        {
          userId: this.$route.query.userId,
          brokerId: this.$route.query.brokerId,
          filterUser: this.isFilterUser,
        }
      );
      this.brokerDetail = response.data.brokerDetails;
      this.mloFullName = response.data.mloFullName;
      this.brokerIds = response.data.brokerId;
      this.mloData = response.data.mloData;
      // response.data.mloData.forEach(element => {
      //   element.userType = "MLO";
      //   this.mloData.push(element);
      // });
      this.adminStaffData = [];
      response.data.adminStaffData.forEach(element => {
        element.userType = "Admin Staff";
        this.adminStaffData.push(element);
      });
      // this.mloData.forEach(e => {
      //   if (e.user.isEmailVerified == true) {
      //     this.verified = true;
      //   }
      // });
      this.vendorData = [];
      response.data.vendorData.forEach(element => {
        this.vendorData.push(element);
      });
      this.dotLoader = false;
    } catch (error) {
      console.log(error.response);
    }
  }

  public resolveVendorTypes() {
    try {
      this.ausVendorSubTypes = ['Fannie Mae', 'Freddie Mac'];
      this.vendorTypes = ["Appraisal"];

      let vendorsType = ["Loan Sifter"];
      vendorsType.forEach(element => {
        let vendor = this.vendorData.filter(e => e.vendorType == element);
        if (vendor.length == 0) {
          this.vendorTypes.push(element);
        }
      });

      // Find vendors by current user and vendorType = 'Credit Vendor'
      const creditVendorsAddedBySelf = 
        this.vendorData.filter(vendor => vendor.addedBy == this.$userId && vendor.vendorType == 'Credit Vendor')
      if (creditVendorsAddedBySelf.length === 0) {
        this.vendorTypes.push('Credit Vendor');
      }

      this.ausVendorType = null
      const ausVendorsAddedBySelf = 
        this.vendorData.filter(vendor => vendor.addedBy == this.$userId && vendor.vendorType == 'AUS Vendor')
      if (ausVendorsAddedBySelf.length < 2) {
        this.vendorTypes.push('AUS Vendor')
        
        const vendorName = _.get(_.head(ausVendorsAddedBySelf), 'vendorName', null)
        this.ausVendorType = vendorName

        if (_.isEmpty(vendorName)) {
          this.ausVendorSubTypes = ['Fannie Mae', 'Freddie Mac']
        } else {
          if (vendorName === 'Fannie Mae') {
            this.ausVendorSubTypes = ['Freddie Mac']
          } else {
            this.ausVendorSubTypes = ['Fannie Mae']
          }
        }
      }
      
      this.vendorTypes.sort();
      this.vendorTypes.push("Other");
    } catch (error) {
      console.log(error);
    }
  }

  public addNewVendorsData() {
    this.newVendorsData = {
      fannieMae: {},
      freddieMac: {}
    };
  }

  private vendorToRemove = null;
  public removeVendor(index) {
    this.vendorToRemove = this.vendorData[index];
    this.$modal.show('removeVendor');
  }

  executeRemoveVendor() {
    this.$modal.show('hide');
    Axios.post(BASE_API_URL + 'wemloStaff/removeBrokerVendor',
        {id: this.vendorToRemove._id}
    ).then(() => {
      this.getBrokerDetails();
    })
  }

  public editVendors(index) {
    try {
      this.isVendorEdit = true;
      this.addNewVendorsData();
      if (this.vendorData[index].vendorType == "AUS Vendor") {
        this.ausVendorType = this.vendorData[index].vendorName;
        if (this.vendorData[index].vendorName == "Fannie Mae")
          this.newVendorsData.fannieMae = this.vendorData[index];
        if (this.vendorData[index].vendorName == "Freddie Mac")
          this.newVendorsData.freddieMac = this.vendorData[index];
      } else {
        this.credit = {
          _id: this.vendorData[index].vendorId,
          vendorName: this.vendorData[index].vendorName
        };
        this.newVendorsData = {
          fannieMae: {},
          freddieMac: {},
          vendorNameAndId: {
            _id: this.vendorData[index].vendorId,
            vendorName: this.vendorData[index].vendorName
          },
          ...this.vendorData[index]
        };
      }
      this.vendorData = JSON.parse(JSON.stringify(this.vendorData));
      this.vendorTypes = [];
      this.vendorTypes.push(this.vendorData[index].vendorType);
      this.addVendorType = this.vendorData[index].vendorType;
      this.subAusType = this.vendorData[index].vendorName;
      this.vendorEditIndex = index;
      this.$modal.show("addVendorModal");
    } catch (error) {
      console.log(error);
    }
  }

  public async addAndEditVendor() {
    try {
      let formValid = await this.$validator.validateAll();
      if (!formValid) {
        return;
      }
      this.inProcess = true;
      let details = {
        addedBy: this.$userId,
        addedByUserType: this.$userType,
        brokerId: this.isFilterUser ? this.brokerIds : this.$route.query.userId,
        createdOn: new Date(),
        vendorType: this.addVendorType,
        vendorData: this.newVendorsData,
        addedFor: this.$route.query.userId,
        deletePreviousVendor: this.newVendorsData.addedBy ? this.newVendorsData._id : null,
      };
      let response = await Axios.post(
        BASE_API_URL + "wemloStaff/addBrokerVendor",
        details);

      this.vendorData = response.data;
      this.$modal.hide("addVendorModal");
      this.addVendorType = null;
      this.subAusType = null;
      this.addNewVendorsData();
      this.$snotify.success(
        this.isVendorEdit
          ? "Vendor updated successfully"
          : "Vendor added successfully"
      );

      await this.fetchCreditVendor();
      await this.getBrokerDetails();

      this.inProcess = false;
      this.isVendorEdit = false;
    } catch (error) {
      this.inProcess = false;
      console.log(error);
    }
  }

  public async validateAndSubmit() {
    this.inProcess = true;
    try {
      let formValid = await this.$validator.validateAll();
      if (
        formValid &&
        (this.currentUserData.email != this.duplicateEmail ||
          this.currentUserData.phone != this.duplicatePhone)
      ) {
        this.currentUserData.firstName =
          this.currentUserData.firstName.charAt(0).toUpperCase() +
          this.currentUserData.firstName.substring(1).toLowerCase();
        this.currentUserData.lastName =
          this.currentUserData.lastName.charAt(0).toUpperCase() +
          this.currentUserData.lastName.substring(1).toLowerCase();
        this.currentUserData.email = this.currentUserData.email.toLowerCase();
        (this.currentUserData.brokerId = this.isFilterUser
          ? this.brokerIds
          : this.$route.query.userId),
          (this.currentUserData.invitee = this.$userId); /*sending broker userId in mlo db brokerid field */
        this.currentUserData["checkDuplicateEmail"] = true;
        this.currentUserData["accessRights"] = ACCESS_RIGHTS_COMPONENTS;
        let url = this.addMLO ? "broker/addMLO" : "broker/addAdminStaff";
        let response = await Axios.post(
          BASE_API_URL + url,
          this.currentUserData);

        let currentUserType = this.currentUserName;
        this.userSpecificLoader = this.currentType;
        if (response.data.status == "success") {
          this.getBrokerDetails();
          this.hideModal("addusermodal");
          this.duplicatePhone = null;
          this.duplicateEmail = null;
          this.$snotify.success(currentUserType + " added successfully");
        } else if (response.data.status == "duplicatePhone") {
          this.duplicatePhone = this.currentUserData.phone;
        } else if (response.data.status == "duplicateEmail") {
          this.duplicateEmail = this.currentUserData.email;
        } else {
          this.duplicatePhone = this.currentUserData.phone;
          this.duplicateEmail = this.currentUserData.email;
          // this.$snotify.error("Something went wrong.");
        }
      }
      this.inProcess = false;
      this.userSpecificLoader = null;
    } catch (error) {
      this.inProcess = false;
      this.userSpecificLoader = null;
      this.$snotify.error(error);
    }
  }

  //Function For Updating The User Info
  public async updateUser() {
    this.inProcess = true;
    try {
      if (this.currentUserData.firstName != null) {
        this.currentUserData.firstName =
          this.currentUserData.firstName.charAt(0).toUpperCase() +
          this.currentUserData.firstName.substring(1).toLowerCase();
      }
      if (this.currentUserData.lastName != null) {
        this.currentUserData.lastName =
          this.currentUserData.lastName.charAt(0).toUpperCase() +
          this.currentUserData.lastName.substring(1).toLowerCase();
      }
      this.currentUserData.userType = this.currentType;

      let currentUserData = this.currentUserData;
      this.currentUserData._id = this.currentUserData.userId;
      let formValid = await this.$validator.validateAll("editUser");

      if (!formValid) {
        this.userSpecificLoader = null;
        this.inProcess = false;
        return;
      }

      let response = await Axios.post(
        BASE_API_URL + "broker/updateUser",
        { data: this.currentUserData, userType: this.currentUserName });
      if (response.data.status == "duplicatePhone") {
        this.duplicatePhone = this.currentUserData.phone;
        this.userSpecificLoader = null;
        this.inProcess = false;
        return;
      }
      this.userSpecificLoader = this.currentType;
      this.hideModal("editUser");
      this.inProcess = false;
      if (currentUserData.userType == "MLO") {
        this.mloData.forEach(e => {
          if (e.userId == currentUserData.userId) {
            Object.keys(e).forEach(key => {
              e[key] = currentUserData[key];
            });
          }
        });
        this.mloData = JSON.parse(JSON.stringify(this.mloData));
      } else {
        this.adminStaffData.forEach(e => {
          if (e.userId == currentUserData.userId) {
            Object.keys(e).forEach(key => {
              e[key] = currentUserData[key];
            });
          }
        });
        this.adminStaffData = JSON.parse(JSON.stringify(this.adminStaffData));
      }
      this.getBrokerDetails();
      this.userSpecificLoader = null;
      this.$snotify.success(currentUserData.userType + " updated successfully");
      this.currentUserData = {};
      this.currentUserName = null;
      this.duplicatePhone = null;
    } catch (error) {
      console.log(error);

      this.inProcess = false;
      this.userSpecificLoader = null;
      this.$snotify.error("Error occurred during updating user");
    }
  }

  // code to generateToken
  public async generateUrl(userId) {
    try {
      let body = {
        userId: userId,
        user: "mlo"
      };
      this.$modal.show("generateToken");

      let response = await Axios.post(
        BASE_API_URL + "broker/saveTokenGenerator",
        body);
      if (response.data.token) {
        this.tokenGenerator =
          CLIENT_URL + "activation-page?mloToken=" + response.data.token;
      }
      if (response.status == 200) {
        this.mloId = userId;
        this.$modal.show("generateToken");
      } else {
        this.$snotify.error("Please try after some time");
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async sendEmailToMlo() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "broker/sendUrlLink",
        { userId: this.mloId, token: this.tokenGenerator, user: "mlo" });
      if (response.status == 200) {
        this.$snotify.success("Email sent Successfully");
        this.$modal.hide("generateToken");
      }
    } catch (error) {}
  }
  public updateEmailBrokerStaffs(modalName, data) {
    this.personalInfo = [
      {
        firstName: data.firstName != null ? data.firstName : "",
        lastName: data.lastName != null ? data.lastName : "",
        email: data.email,
        userId: data.userId,
        userType: data.userType,
        modalName: modalName
      }
    ];
    this.$refs.updateUserStatus["openModalOrClose"](this.personalInfo);
  }
  public updateEmail() {
    this.updateStaffEmail = "";
    this.getBrokerDetails();
  }

  public async cancel() {
    this.lenderInfo = {};
    this.defaultLenderName = "Add Lender";
    this.defaultSubmitName = "Add";
    this.$modal.hide("addLender");
  }

  public closeModal() {
    this.lenderInfo = {};
    this.defaultLenderName = "Add Lender";
    this.defaultSubmitName = "Add";
    this.$modal.hide("addLender");
  }

  get userType() {
    return this.$store.state.sessionObject.type;
  }

  public openAddVendorModal() {
    this.vendorTypes = VENDOR_TYPES;
    this.$modal.show('addVendorModal');
  }

  public closeVendorModel() {
    this.addNewVendorsData();
    this.addVendorType = null;
    this.subAusType = null;
    this.isVendorEdit = false;
    this.$modal.hide("addVendorModal");
  }
  public async openModal(user, i) {
    try {
      if (user.userType == "MLO") {
        this.list = this.mloData;
      } else {
        this.list = this.adminStaffData;
      }
      let sure = false;
      let msg = !user.user.userActive
        ? "Are you sure you want to deactivate user?"
        : "Are you sure you want to activate user?";
      await this.$dialog
        .confirm(msg)
        .then(e => {
          sure = true;
        })
        .catch(f => {
          sure = false;
          user.user.userActive = !user.user.userActive;
          this.$snotify.info("No changes");
        });
      if (sure) {
        let loanTxnId = user.userId;
        let response = await Axios.post(
          BASE_API_URL + "common/deactivateUser",
          {
            loanTxnId: loanTxnId,
            activate: user.user.userActive,
            userType: user.user.userType
          });
        let message = response.data.userActive
          ? "User has been activated"
          : "User has been deactivated";
        // data[0].update ? "User Activated" : "User Deactivated"
        this.$snotify.success(message);
      }

      // this.statusChangingIndex = i;
      // this.userInfo = JSON.parse(JSON.stringify(user));
      // // this.open = true;
      // // this.$snotify.clear();
      // // this.$modal.show("activeOrDeactiveAccount");
      // this.borrowersLoanTxnId = this.userInfo;
      // // this.showPopUp = true
      // this.$refs.updateUserInfo["openModalOrClose"](this.userInfo);
    } catch (error) {
      console.log(error);
    }
  }

  public async closeModal1() {
    this.mloData[this.statusChangingIndex].user.userActive = !this.mlo.user
      .userActive;
    this.mlo = {};
    await this.getBrokerDetails();
    this.$modal.hide("activeOrDeactiveAccount");
  }

  public async copyLink() {
    var copyText: any = document.getElementById("myInput");
    copyText.select();
    document.execCommand("copy");
  }
  public async changeUserActive(user) {
    try {
      let data = [
        {
          userId: user.userId,
          update: !user.user.userActive
        }
      ];
      let response = await Axios.post(
        BASE_API_URL + "broker/changeUserActive",
        data);
      this.$snotify.clear();
      if (response.status == 200) {
        this.$snotify.success(data[0].update ? "User Activated" : "User Deactivated")
      }
      this.$modal.hide("activeOrDeactiveAccount");
      this.getBrokerDetails();
      this.mlo = {};
    } catch (error) {
      console.log(error.response);
    }
  }
  public async deleteMloAdminStaff(id, first, last, type) {
    try {
      this.delType = type;
      this.delMloAdminId = id;

      let response = await Axios.post(
        BASE_API_URL + "superAdmin/deleteMloAdminStaff",
        {
          userId: id,
          name: first + " " + last,
          userType: type
        });
      if (response.data.code == 420) {
        this.userExist = true;
        this.message = response.data.message;
      } else {
        this.userExist = true;
        this.deleteOpt = true;
        this.message = response.data.message;
      }
      this.$modal.show("deleteModal");
    } catch (error) {
      console.log(error);
    }
  }
  public async deleteMloAdminStaffInfo(id) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/deleteMloAdminStaffInfo",
        {
          userId: id,
          userType: this.delType
        });
      if (response.data.code == 200) {
        this.closeDelModal();
        this.$snotify.success(response.data.message);

        await this.getBrokerDetails();
      }
    } catch (error) {
      console.log(error);
    }
  }
  public closeDelModal() {
    this.userExist = false;
    this.delMloAdminId = null;
    this.message = null;
    this.deleteOpt = false;
    this.$modal.hide("deleteModal");
  }
  public openConfirmDeleteFileModal(brokerId) {
    this.body = {
      brokerId: brokerId
    };
    this.$modal.show("confirmDeleteFileModal");
    this.showDeleteMLOComponent = true;
  }

  public setUserNameAndPassword(lender) {
    this.lender = lender;
    this.$modal.show("receviedCredential");
  }

  public async editWemloCredential(lender) {
    this.editLenderData = Object.assign({}, lender);
    this.defaultSubmitName = "Update";
    this.wemloLender = this.editLenderData;
    this.getAppovedLenderList();
    this.$modal.show("receviedCredential");
  }

  public async getMLODetails(mlo) {
    this.$router.replace({
      path: "/super-admin/broker-detail",
      query: { userId: mlo.userId, userType: mlo.userType }
    });
    this.isFilterUser = true;
    //this.checkMloDetails();
    this.fetchCreditVendor();
    this.getAppovedLenderList();
    await this.getBrokerDetails();
  }

  public async modifyCredential() {
    try {
      let formValid = await this.$validator.validateAll();
      if (!formValid) {
        this.inProcess = false;
        return;
      }
      let wemloLenderData: any = {
        wemloLender: this.wemloLender,
        brokerId: this.$route.query.userId,
        userType: this.$userType,
        lenderInfo: this.lender
      };
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/modifyCredential",
        wemloLenderData);
      this.$snotify.success("Credential added successfully!");
      this.$modal.hide("receviedCredential");
      this.getBrokerDetails();
      await this.getAppovedLenderList();
    } catch (error) {
      console.log(error);
    }
  }

  /**Function for adding and deleting lender */
  public hideModel() {
    this.$modal.hide("openLenderModel");
    this.defaultLenderName = "Add Lender";
    this.defaultSubmitName = "Add";
    this.lenderInfo = {
      lenderNameAndId: {}
    };
    this.selectLender = false;
  }

  public async editLenderInfo(lender) {
    this.editLenderData = Object.assign({}, lender);
    this.defaultLenderName = "Edit Lender";
    this.defaultSubmitName = "Update";
    this.lenderInfo = this.editLenderData;
    this.getAppovedLenderList();
    this.$modal.show("openLenderModel");
  }

  public changeLenderName(type) {
    this.hasErrorMessage = false;
    if (type == "input") {
      if (this.isPpeLender) this.ppelenderName = this.lenderInfo.lenderName;
    } else {
      this.hasLenderName = true;
      if (this.isPpeLender)
        this.ppelenderName =
          this.lenderInfo.lenderNameAndId == "Others"
            ? this.lenderInfo.lenderNameAndId
            : this.lenderInfo.lenderNameAndId.lenderName;
    }
  }

  public async saveLenderInfo() {
    try {
      this.inProcess = true;
      let formValid = await this.$validator.validateAll();
      if (!formValid) {
        this.inProcess = false;
        return;
      }
      let lenderName = null;
      let lenderId = null;
      if (
        this.lenderInfo.lenderNameAndId == "Others" ||
        this.lenderInfo.lenderNameAndId.lenderName == "Others"
      ) {
        lenderName = this.lenderInfo.lenderName;
        lenderId = this.lenderInfo.lenderNameAndId._id
          ? this.lenderInfo.lenderNameAndId._id
          : null;
      } else {
        lenderName = this.lenderInfo.lenderNameAndId.lenderName;
        lenderId = this.lenderInfo.lenderNameAndId._id;
      }
      let lenderData: any = {
        lenderName: lenderName,
        lenderId: lenderId,
        addedFor: this.$route.query.userId,
        brokerId: this.isFilterUser ? this.brokerIds : this.$route.query.userId,
        userType: this.$userType,
        lenderInfo: this.lenderInfo,
        type: this.defaultLenderName,
        filterUser: this.isFilterUser
      };
      let response = await Axios.post(
        BASE_API_URL + "onBoarding/saveLenderInfo",
        { lenderData });
      if (response.data.statusCode == 500) {
        this.inProcess = false;
        this.hasErrorMessage = true;
        this.errorMessage = response.data.message;
      } else {
        await this.getAppovedLenderList();
        await this.getBrokerDetails();
        this.$snotify.success("Lender added successfully!");
        this.inProcess = false;
        this.hideModel();
      }
    } catch (error) {
      console.log(error);
    }
  }

  //To get the Approved lender data
  public async getAppovedLenderList() {
    try {
      this.lenderDotLoader = true;
      let body = {
        userId: this.$userId,
        brokerId: this.$route.query.userId,
        userType: this.$userType,
        filterUser: this.$route.query.userType,
        type: this.defaultLenderName
      };
      let response = await Axios.post(
        BASE_API_URL + "onBoarding/getAppovedLenderList",
        body);

      this.lenderList = response.data.lender;
      this.addedLenders = response.data.addedData;
      this.lenderDotLoader = false;
    } catch (error) {
      console.log(error.response);
    }
  }

  /**End */

  /**Function for credit vednor */
  public async fetchCreditVendor() {
    try {
      this.vendorDotLoader = true;
      let body = {
        userId: this.$userId,
        brokerId: this.$route.query.userId,
        userType: this.$userType,
        filterUser: this.$route.query.userType,
        vendorType: "Credit Vendor"
      };
      let response = await Axios.post(
        BASE_API_URL + "onBoarding/fetchCreditVendor",
        body);
      let responseData = response.data;
      if (responseData.addedVendor) {
        this.vendorDotLoader = false;

        this.vendorData = responseData.addedVendor;
        this.creditVendorList = responseData.creditVendorList;
      } else {
        this.vendorDotLoader = false;

        this.creditVendorList = responseData.creditVendorList;
      }
    } catch (error) {
      this.vendorDotLoader = false;

      console.log(error);
    }
  }

  public getUserType() {
    if (this.$route.query.userType == "MLO") {
      return "MLO Information";
    } else {
      return "Broker Information";
    }
  }
  public async getData(lender) {
    this.lenderInfo.lenderNameAndId = {
      _id: lender._id,
      lenderName: lender.lenderName
    };

    if (this.lenderInfo.lenderNameAndId._id) {
      this.selectLender = false;
    }
  }

  // public checkMloDetails() {
  //   // this.mloData.forEach(e => {
  //   //   if (e.userId.toString() != this.$route.query.userId) {
  //   //     console.log(e);
  //   //     return (this.showData = true);
  //   //   }
  //   // });
  //   let c = this.mloData.filter(
  //     e => e.userId.toString() != this.$route.query.userId
  //   );
  //   if (c.length > 0) {

  //     this.showData = true;
  //   } else {

  //     this.showData = false;
  //   }
  // }

  async mounted() {
    if (this.$route.query.userType == "MLO") {
      this.isFilterUser = true;
    } else {
      this.isFilterUser = false;
    }
    this.getAppovedLenderList();
    await this.getBrokerDetails();
  }
}
